
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        









































































































































































































































.net-comparison-table-outer {
  @extend %table-legacy;
}

.net-comparison-table {
  position: relative;

  .table__layout {
    grid-template-rows: 15rem 1fr 16rem;
  }

  .table-content__header {
    top: 0;
  }

  .table-content__header__block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: $spacing;

    @include mq($until: m) {
      padding: 0 $spacing $spacing;
    }

    &.plume {
      margin-right: 0;
    }
  }

  .price-box {
    background-color: none;
    box-shadow: none;
  }
}

.net-comparison-table-expand {
  position: relative;
  //overflow: hidden;
  width: 100%;
  height: 0;
}

.net-comparison__header__title {
  margin-bottom: 4rem;

  @include mq($until: m) {
    margin-top: 2rem;
  }
}

.net-comparison__header__picture {
  max-height: 11rem;
  margin-bottom: 4rem;
}

.net-comparison__header__extra {
  @extend %fw-medium;

  color: $c-nevada;
  font-size: 1.3rem;
  text-align: center;

  ::v-deep strong {
    color: $c-orange;
  }
}

.net-comparison__table__label {
  font-size: 1.6rem;
}

.net-comparison__table__cell {
  @extend %fw-medium;

  padding: 2rem;
  line-height: 1.2;
  text-align: center;

  strong {
    display: block;
    margin: 0 auto 0.5rem;
    line-height: 1.3;
  }

  span {
    @extend %fw-normal;

    color: $c-gray-light;
    font-size: 1.5rem;
    line-height: 1.1;
  }
}

/* stylelint-disable declaration-no-important */
.net-comparison__cta {
  margin-bottom: 0 !important;
}

.net-comparison__cta__block {
  padding: 4rem 0 !important;

  @include mq(m) {
    padding: 4rem 2rem !important;
  }
}

.net-comparison__cta__btn {
  margin-bottom: 2rem;
}

.net-comparison-trigger {
  text-align: center;

  @include mq($until: m) {
    display: none;
  }
}
